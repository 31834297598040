import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom' 
import axios from "axios"; // Axios for making API requests
const DataTable = () => {
    const [data, setData] = useState([]); // State to hold fetched data
    const [filter, setFilter] = useState(""); // State to hold filter input
    const [loading, setLoading] = useState(true); // State for loading indication
    const [error, setError] = useState(null); // State for error handling

    // Fetch data from API when component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://zoopup.com/api/gamereport"); // Replace with your API URL
                setData(response.data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                setError("Error fetching data");
                setLoading(false); // Set loading to false on error
            }
        };

        fetchData();
    }, []); // Empty dependency array means this runs only once on mount

    // Filtered data based on mobile number
    const filteredData = data.filter((item) =>
        item.mobile.includes(filter)
    );
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleString(); // Converts to a human-readable format
    };
    // Handle loading or error states
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="data-table-container">
            <h2>Report </h2>

            {/* Input for filter */}
            <input
                type="text"
                className="filter-input"
                placeholder="Filter by mobile number"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
            />

            {/* Table to display filtered data */}
            <div className="table-wrapper">
                <table className="data-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Q1</th>
                            <th>Q2</th>
                            <th>Q3</th>
                            <th>Q4</th>
                            <th>Q5</th>
                            <th>Q6</th>
                            <th>Q7</th>
                            <th>Q8</th>
                            <th>Q9</th>
                            <th>Q10</th>
                            <th>Q11</th>
                            <th>Q12</th>
                            <th>Q13</th>
                            <th>Q14</th>
                            <th>Q15</th>
                            <th>Q16</th>
                            <th>Created-Date</th>
                            <th>Updated-Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item) => (
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>

                                <td>{item.mobile}</td>
                                <td>{item.q1}</td>
                                <td>{item.q2}</td>
                                <td>{item.q3}</td>
                                <td>{item.q4}</td>
                                <td>{item.q5}</td>
                                <td>{item.q6}</td>
                                <td>{item.q7}</td>
                                <td>{item.q8}</td>
                                <td>{item.q9}</td>
                                <td>{item.q10}</td>
                                <td>{item.q11}</td>
                                <td>{item.q12}</td>
                                <td>{item.q13}</td>
                                <td>{item.q14}</td>
                                <td>{item.q15}</td>
                                <td>{item.q16}</td>
                                <td>{formatDate(item.created_at)}</td>
                                <td>{formatDate(item.updated_at)}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DataTable;

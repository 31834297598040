import React, { useState,useEffect } from 'react';
import './../App.css';
import { useNavigate } from 'react-router-dom';
const QuestionScreen1 = () => {
    useEffect(() => {
        document.title = "JCI Treasure Hunt 2024"; // Set the new page title
    }, []); 
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [otpRequested, setOtpRequested] = useState(false);
   
    const [otpVerified, setOtpVerified] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [verify, setVerify] = useState({ sessionid: '', otp: '' });
    const handleNext = () => {
        navigate('/questionnaire',{ state: { mobile ,name} });
      };
    const handleOtpChange = (e) => {
        setVerify(prevState => ({
            ...prevState,
            otp: e.target.value
        }));
    };
    
    
    const handleGetOtp = () => {
        if (!name || !mobile) {
            setError('Name and mobile number are required.');
            return;
        }
        fetch('https://zoopup.com/api/mobile/client/sendotp/' + mobile, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({ name, mobile }),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.Status === 'Success') {
                    setVerify.sessionid = data.Details;
                    setVerify(prevState => ({ ...prevState, sessionid: data.Details }));

                    setOtpRequested(true);
                    setError('');
                } else {
                    setError('Failed to request OTP. Please try again.');
                }
            })
            .catch(error => setError('Error: ' + error.message));
    };

    const handleVerifyOtp = () => {
       
        if (!verify.otp || !mobile) {
            setError('OTP are required.');
            return;
        }
        fetch('https://zoopup.com/api/mobile/client/verifyotp-noAuth', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(verify),
        })
            .then(response => response.json())
            .then(data => {
                if (data.Status === 'Success') {
                    setOtpVerified(true);
                    setSuccess(true);
                    setError('');
                } else {
                    setError('Invalid OTP. Please try again.');
                }
            })
            .catch(error => setError('Error: ' + error.message));
    };

    return (
        <div className="questionnaire-background">
            {success ? (
               <div className="card">
                    <header className="header">
                        <h1>Treasure Hunt 2024</h1>
                        <h2>Team JCI Lucknow Metropolitan</h2>
                    </header>
                    <div className="success-message">
                        <h3>Congrats!!! you have been allotted "Alien KIT No 1"</h3>
                        <audio controls>
                            <source src="/audio/1.mp3" type="audio/mpeg" />
                            Your browser does not support the audio element.
                        </audio>
                    </div>
                    <button type="button" onClick={handleNext}>Next</button>
                </div>
            ) : (
                <div>
                    <header className="header">
                    <h1>Welcome to " Aliens of the lost World"</h1>
                        <h2>Treasure Hunt 2024</h2>
                        <h3>Team JCI Lucknow Metropolitan</h3>
                        
                        
                    </header>
                    <div className="container">
                        <h4>Enter your details to get an OTP</h4>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile:</label>
                            <input
                                type="text"
                                id="mobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                placeholder="Enter your mobile number"
                                required
                            />
                        </div>
                        {otpRequested && !otpVerified ? (
                            <div>
                                <div className="form-group">
                                    <label htmlFor="otp">Enter OTP:</label>
                                    <input
                                        type="text"
                                        id="otp"
                                        value={verify.otp}
                                        onChange={handleOtpChange}
                                        placeholder="Enter OTP"
                                        required
                                    />
                                </div>
                                <button type="button" className="verify-button" onClick={handleVerifyOtp}>
                                    Verify
                                </button>
                            </div>
                        ) : (
                            <button type="button" className="get-otp-button" onClick={handleGetOtp}>
                                Get OTP
                            </button>
                        )}
                        {error && <div className="error-text">{error}</div>}

                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionScreen1;

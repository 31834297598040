import React, { useState, useRef, useEffect } from 'react';
import './../App.css';
import axios from 'axios';
import Swal from 'sweetalert2'; 
import { useLocation, useNavigate } from 'react-router-dom' 
const encryptMobileNumber = (mobileNumber) => {
    return btoa(mobileNumber);
};


const questions = [
    /* Array of 20 questions */
    "Solve the star puzzle and name the star from which you have come.", "Read the Story and answer your star's Name", "Complete your ``Tongue Twister`` Task and send it to WhatsApp", "Identify location of your Ship based on clues and answer your next location.",
    "Solve the Rocket puzzle and name the Rocket in which you are travelling.", "Solve the ribbon and answer code to enter Rocket", "Record video of releasing Alien from the cryo-jail and send on WhatsApp", "Identify location of your Galaxy based on clues and answer your next location.",
    "Solve the Galaxy location puzzle and name the Galaxy you are travelling to. ", "Solve the Code and answer code to enter Galaxy ``Milky Way``", "Record the video of ask 3 wish from Alien and send on WhatsApp.", "Identify location of your Planet Earth based on clues and answer your next location.",
    "Solve the puzzle of destination Planet and name the Planet you are travelling to.", "Solve the Code and answer code to enter Planet Earth", "Record the video of first human you meet wearing mask and send on WhatsApp.", "Identify location of Landing Site based on clues and answer your next location.",

];
const heading = [
    "The First Mission: Identify the star from which you have come.",
    "The Second Mission: Identify the mode of travel you will use to reach Earth.",
    "The Third Mission: Identify the directions and route map to reach Earth.",
    "The Fourth Mission: Identify the galaxy in which Planet Earth exists.",


];
const audioFiles = [
    '/audio/2.mp3', '/audio/3.mp3', '/audio/4.mp3',
    '/audio/5.mp3'
];


const Questionnaire = () => {
    const location = useLocation(); 
    const navigate = useNavigate();
    const [mobile, setMobile] = useState(""); // Mobile number state
    const [name, setName] = useState(""); // Mobile number state
        const [answers, setAnswers] = useState({
        q1: "", q2: "", q3: "", q4: "", q5: "", q6: "", q7: "", q8: "",
        q9: "", q10: "", q11: "", q12: "", q13: "", q14: "", q15: "", q16: ""
    });
    const [loading, setLoading] = useState(true);
    
    const audioRef = useRef(null);
    const [currentPart, setCurrentPart] = useState(0);
    useEffect(() => {
        document.title = "JCI Treasure Hunt 2024"; 
        if (location.state && location.state.mobile) {
            setMobile(location.state.mobile);  
            setName(location.state.name);
           
        }
        else {
           
            navigate('/');
            return;
        }
        axios.get('https://zoopup.com/api/game/' + encryptMobileNumber(location.state?.mobile))
            .then((response) => {

                const data = response.data;

                if (data) {
                    console.log(data.q1);
                    setMobile(data.mobile || "");
                    setAnswers({
                        q1: data.q1 || "",
                        q2: data.q2 || "",
                        q3: data.q3 || "",
                        q4: data.q4 || "",
                        q5: data.q5 || "",
                        q6: data.q6 || "",
                        q7: data.q7 || "",
                        q8: data.q8 || "",
                        q9: data.q9 || "",
                        q10: data.q10 || "",
                        q11: data.q11 || "",
                        q12: data.q12 || "",
                        q13: data.q13 || "",
                        q14: data.q14 || "",
                        q15: data.q15 || "",
                        q16: data.q16 || ""
                    });
                    console.log(answers);
                }
                console.log(answers);
                setLoading(false);

            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [location.state]);


    const handleAnswerChange = (questionIndex, answer) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionIndex]: answer
        }));
    };

    const handlePrev = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0; // Reset to the beginning
            audioRef.current.load();          // Reload the audio element
        }
        if (currentPart > 0) {
            setCurrentPart(prevPart => prevPart - 1);
        }
    };

    const handleNext = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0; // Reset to the beginning
            audioRef.current.load();          // Reload the audio element
        }

        if (currentPart < 4) {
            setCurrentPart(prevPart => prevPart + 1);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
       
        axios.post('https://zoopup.com/api/game', {
            mobile: encryptMobileNumber(location.state?.mobile), // Sending the encrypted mobile number
            answers: answers,
            username:name,
        })
            .then((response) => {
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Answers saved successfully!'
                });
                if (currentPart === 3) {
                    setCurrentPart(prevPart => 4);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'There was an error saving your answers. Please try again.'
                });
                setLoading(false);
            });


    };


    const startIndex = currentPart * 4;
    const endIndex = startIndex + 4;
    const partQuestions = questions.slice(startIndex, endIndex);

    return (
        
        <div className="questionnaire-background">
            <div className="card">
                
              
              
                {currentPart === 4 ? (
                    <span style={{ textAlign: 'center', flex: '1' }}>
                        <h2>The Final Mission: Land at "Landing Location" of Planet Earth, your Final Destination.</h2>
                        <h4> Congratulations you have completed your "Aliens of the lost Planet" Treasure hunt.</h4>
                        <h1>Welcome to Planet Earth</h1>
                    </span>
                ) : (
                    <div>
                        <h4>Alien Kit No {currentPart + 1}</h4>
                        <div style={{ textAlign: 'center' }}>
                            <h2>{heading[currentPart]}</h2>

                            {/* Audio */}
                            <audio controls ref={audioRef}>
                                <source src={audioFiles[currentPart]} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>

                            <div className="form-group">
                                {partQuestions.map((question, index) => (
                                    <div key={index} style={{ marginBottom: '10px', padding: '10px' }}>
                                        <label>{question}</label>
                                        {index % 3 === 2 ? (
                                            <div style={{ marginTop: '10px' }}>
                                                <label style={{ marginRight: '20px', display: 'inline-flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"
                                                        value="yes"
                                                        onChange={() => handleAnswerChange('q' + (startIndex + index + 1), 'yes')}
                                                        checked={answers['q' + (startIndex + index + 1)] === 'yes'}

                                                    /> Yes</label>
                                                <label style={{ marginRight: '20px', display: 'inline-flex', alignItems: 'center' }}>
                                                    <input
                                                        type="radio"

                                                        value="no"
                                                        onChange={() => handleAnswerChange('q' + (startIndex + index + 1), 'no')}
                                                        checked={answers['q' + (startIndex + index + 1)] === 'no'}
                                                    /> No </label>
                                            </div>
                                        ) : (

                                            <input
                                                style={{ marginTop: '10px' }}
                                                type="text"
                                                value={answers['q' + (startIndex + index + 1)] || ''}
                                                onChange={(e) => handleAnswerChange('q' + (startIndex + index + 1), e.target.value)}

                                            />)}
                                    </div>
                                ))}
                            </div>

                            <div style={{
                                marginBottom: '20px',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <button onClick={handlePrev} disabled={currentPart === 0}>Previous</button>
                                <button style={{ backgroundColor: 'red', color: 'white', padding: '10px 20px', border: 'none', cursor: 'pointer' }} onClick={handleSubmit}>Submit</button>
                                {currentPart < 3 && (<button onClick={handleNext} disabled={currentPart === 4}>Next</button>)}
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </div>
        
    );
};

export default Questionnaire;

import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import QuestionScreen1 from './components/QuestionScreen1';
import Questionnaire from './components/Questionnaire'; 
import Reportdata from './components/DataReport';


const App = () => {
  
  return (
    <Router>
    <Routes>
      <Route path="/" element={<QuestionScreen1 />} />
      <Route path="/questionnaire" element={<Questionnaire />} />
      <Route path="/alldata" element={<Reportdata/>} />
    </Routes>
  </Router>
  );
};

export default App;
